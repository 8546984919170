/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = async () => {
  if (!("scrollBehavior" in document.documentElement.style)) {
    // eslint-disable-next-line
    await import("scroll-behavior-polyfill")
  }
}
