// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-findings-tsx": () => import("./../src/pages/findings.tsx" /* webpackChunkName: "component---src-pages-findings-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-story-ahmad-tsx": () => import("./../src/pages/story/ahmad.tsx" /* webpackChunkName: "component---src-pages-story-ahmad-tsx" */),
  "component---src-pages-story-amir-tsx": () => import("./../src/pages/story/amir.tsx" /* webpackChunkName: "component---src-pages-story-amir-tsx" */),
  "component---src-pages-story-khairul-tsx": () => import("./../src/pages/story/khairul.tsx" /* webpackChunkName: "component---src-pages-story-khairul-tsx" */),
  "component---src-pages-story-odin-tsx": () => import("./../src/pages/story/odin.tsx" /* webpackChunkName: "component---src-pages-story-odin-tsx" */),
  "component---src-pages-story-rahma-tsx": () => import("./../src/pages/story/rahma.tsx" /* webpackChunkName: "component---src-pages-story-rahma-tsx" */)
}

